import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  const { vueApp } = nuxtApp

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: runtimeConfig.public.SENTRY_DSN || '',
    environment: runtimeConfig.public.SENTRY_ENVIRONMENT || 'local',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration({ levels: ['warn', 'error'] })
    ],
    tracesSampleRate: parseFloat(
      runtimeConfig.public?.SENTRY_TRACE_SAMPLE_RATE || '0.0'
    ),
    release: runtimeConfig.public.SENTRY_RELEASE || 'local',
    attachStacktrace: true,
    autoSessionTracking: true,
    logErrors: true,
    tracingOptions: {
      hooks: ['mount', 'update', 'create'],
      trackComponents: true
    },
  })

  vueApp.mixin(
    Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update']
    })
  )

  nuxtApp.provide('sentry', Sentry)

  return {
    provide: {
      $sentry: Sentry
    }
  }
})
