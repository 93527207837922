import { configure, defineRule, Form, Field, ErrorMessage } from 'vee-validate'

configure({
  validateOnInput: true
})

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('VForm', Form)
  nuxtApp.vueApp.component('VField', Field)
  nuxtApp.vueApp.component('VErrorMessage', ErrorMessage)

  const { t } = nuxtApp.$i18n

  defineRule('minmax', (value, { min, max }) => {
    if (value.length >= min && value.length <= max) {
      return true
    }
    return t('form.minmax', { min, max })
  })

  defineRule('dutch_zipcode', (value) => {
    if (/^[1-9][0-9]{3} ?[A-Z]{2}$/.test(value)) {
      return true
    }
    return t('form.dutch_zipcode')
  })

  defineRule('dutch_phone_number', (value) => {
    if (
      /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/.test(
        value
      )
    ) {
      return true
    }
    return t('form.dutch_phone_number')
  })

  defineRule('password', (value, { target }) => {
    if (value === target) {
      return true
    }
    return t('form.password')
  })

  defineRule('iban', (value) => {
    if (
      /^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{10}([a-zA-Z0-9]?){0,16}$/.test(
        value.replace(/\s/g, '')
      )
    ) {
      return true
    }
    return t('form.iban')
  })

  defineRule('tiptap', (value) => {
    if (!value) return
    if (
      value.toString().trim() === '<p></p>' ||
      value.toString().replace(/\s+/g, ' ') === '<p> </p>'
    ) {
      return t('c_Form.Invalid tiptap')
    }
    return true
  })

  defineRule('required', (value) => {
    if (!value) {
      return t('c_Form.Field required')
    }

    return true
  })

  defineRule('email', (value) => {
    // Field is empty, should pass
    if (!value || !value.length) {
      return true
    }
    // Check if email
    if (!/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i.test(value)) {
      return t('c_Form.Invalid email')
    }
    return true
  })
})
