import { default as admin0eiku7Jj1aMeta } from "/app/pages/admin.vue?macro=true";
import { default as callbackFBKqDD9hoQMeta } from "/app/pages/callback.vue?macro=true";
import { default as conceptdhhJuLNGZCMeta } from "/app/pages/concept.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as logoutHaVXWqg8UAMeta } from "/app/pages/logout.vue?macro=true";
import { default as nologin3TZl64uAbuMeta } from "/app/pages/nologin.vue?macro=true";
import { default as othersH0zy7NnP9MMeta } from "/app/pages/others.vue?macro=true";
import { default as conceptX364NtmjBzMeta } from "/app/pages/teacher/concept.vue?macro=true";
import { default as indexjJXhGMXpFhMeta } from "/app/pages/teacher/index.vue?macro=true";
import { default as editg873f1troAMeta } from "/app/pages/teacher/test-questions/edit.vue?macro=true";
import { default as indexgWdI0CxbTqMeta } from "/app/pages/teacher/test-questions/index.vue?macro=true";
import { default as testL3AVDvhyXeMeta } from "/app/pages/test.vue?macro=true";
export default [
  {
    name: "admin___en",
    path: "/admin",
    meta: admin0eiku7Jj1aMeta || {},
    component: () => import("/app/pages/admin.vue")
  },
  {
    name: "callback___en",
    path: "/callback",
    meta: callbackFBKqDD9hoQMeta || {},
    component: () => import("/app/pages/callback.vue")
  },
  {
    name: "concept___en",
    path: "/concept",
    meta: conceptdhhJuLNGZCMeta || {},
    component: () => import("/app/pages/concept.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "logout___en",
    path: "/logout",
    meta: logoutHaVXWqg8UAMeta || {},
    component: () => import("/app/pages/logout.vue")
  },
  {
    name: "nologin___en",
    path: "/nologin",
    component: () => import("/app/pages/nologin.vue")
  },
  {
    name: "others___en",
    path: "/others",
    component: () => import("/app/pages/others.vue")
  },
  {
    name: "teacher-concept___en",
    path: "/teacher/concept",
    meta: conceptX364NtmjBzMeta || {},
    component: () => import("/app/pages/teacher/concept.vue")
  },
  {
    name: "teacher___en",
    path: "/teacher",
    meta: indexjJXhGMXpFhMeta || {},
    component: () => import("/app/pages/teacher/index.vue")
  },
  {
    name: "teacher-test-questions-edit___en",
    path: "/teacher/test-questions/edit",
    meta: editg873f1troAMeta || {},
    component: () => import("/app/pages/teacher/test-questions/edit.vue")
  },
  {
    name: "teacher-test-questions___en",
    path: "/teacher/test-questions",
    meta: indexgWdI0CxbTqMeta || {},
    component: () => import("/app/pages/teacher/test-questions/index.vue")
  },
  {
    name: "test___en",
    path: "/test",
    component: () => import("/app/pages/test.vue")
  }
]