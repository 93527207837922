import { library } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeIcon,
  FontAwesomeLayers
} from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/pro-solid-svg-icons'

// Don't add Font Awesome CSS automatically
library.add(fas)

export default defineNuxtPlugin((nuxtApp) => {
  // Register the Font Awesome components
  nuxtApp.vueApp.component('FontAwesome', FontAwesomeIcon)
  nuxtApp.vueApp.component('FontAwesomeLayers', FontAwesomeLayers)
})
